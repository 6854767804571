
import React, { useEffect, useState } from 'react';
import {auth , provider}  from '../firebase.js';
import { useNavigate } from 'react-router-dom';
import subject from '../myservice.js';
  
const Login = () => {
  const navigate = useNavigate();
  const [logged, setLogged] = useState(null);

  useEffect(() => {
      //navigate("/panel");
  }, [logged]);
 
    // Sign in with google
    const signin = () => {
      auth.signInWithPopup(provider).catch(alert).then((d)=>{
        console.log(d.user.displayName);
        console.log(d.user.email);
        console.log(d.user.refreshToken);
        localStorage.setItem("token", d.user.refreshToken);
        localStorage.setItem("email", d.user.email);
        localStorage.setItem("name", d.user.displayName);
        subject.next(d.user.refreshToken);
        navigate("/panel");
        //setLogged(d.user.refreshToken);
       });
    }
      
    return (
        <div>
            <center>
                <button className='btn btn-primary' style={{"marginTop" : "200px"}} 
                onClick={signin}>Sign In with Google</button>
            </center>
        </div>
    );
}
  
export default Login;