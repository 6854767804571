function Getotp() {
    
    return (
      <div className="container">
        <div className="row">
            <div className="col-md-12 text-center">
            <div className="shadow p-4 rounded mt-5">
                <h1>Get OTP</h1>
                
                  <select className="form-control">
                    <option>Select</option>
                    <option>WhatsApp Rs.20</option>
                    <option>Facebook Rs.20</option>
                    <option>Telegram Rs.20</option>
                    <option>Winzo Rs.30</option>
                    <option>Other Rs.35</option>
                  </select>
                  <button className="btn btn-primary" onClick={()=>{alert('Not enough balance please add money')}}>Get Number</button>

                  <p>Nothing to show here</p>
            </div>
            </div>
        </div>
      </div>
    );
  }
  
  export default Getotp;