import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import subject from "../myservice";

function Header() {

  subject.subscribe((data)=>{
    console.log("SUBJECT: ", data);
    setLogged(data);
  })

  const navigate = useNavigate();
  const [logged, setLogged] = useState(null);
  const logout = () => {
    localStorage.clear();
    setLogged(null);
    subject.next(null);
  };

  useEffect(() => {
    if(logged == null){
      navigate("/login");
    }
  }, [logged]);

  return (
    <>
      <Navbar bg="primary" variant="dark">
        <Container>
          <Navbar.Brand href="#home">India OTP App</Navbar.Brand>
          <Nav className="me-auto">
            {
            (logged == null ? (
              <Link className="myLink" to="/login">
                Login
              </Link>
            ) : (
              <>
              <Link className="myLink" onClick={logout}>
                Logout
              </Link>
<Link className="myLink" to="/panel">
Panel
</Link>
<Link className="myLink" to="/getotp">
GetOtp
</Link>
</>

            ))

            }
            
            <Link className="myLink" to="/support">
              Support
            </Link>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
