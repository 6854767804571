import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyDiXkbo76vzaz5CftOoLvNs2HTSJGq0MPQ",
    authDomain: "otpapp-react.firebaseapp.com",
    projectId: "otpapp-react",
    storageBucket: "otpapp-react.appspot.com",
    messagingSenderId: "1071044559130",
    appId: "1:1071044559130:web:1437c04ad70d2c15ec6ca3",
    measurementId: "G-80PGY0742G"
};

firebase.initializeApp(firebaseConfig);
var auth = firebase.auth();
var provider = new firebase.auth.GoogleAuthProvider(); 
export {auth , provider};