function Transactions() {
    return (
      <div className="container">
        <div className="row">
            <div className="col-md-12">
                <div className="shadow p-4 rounded mt-5">
                    <h1>Transactions</h1>
                    <p>No transactions to show</p>
                </div>
            </div>
        </div>
      </div>
    );
  }
  
  export default Transactions;