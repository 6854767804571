function Home() {
    return (
      <div className="container">
        <div className="row">
            <div className="col-md-12">
                <h1>Home</h1>
            </div>
        </div>
      </div>
    );
  }
  
  export default Home;