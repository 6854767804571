function Support() {
    return (
      <div className="container">
        <div className="row">
            <div className="col-md-12 text-center">
            <div className="shadow p-4 rounded mt-5">
                <h1>Support</h1>
                <p>https://t.me/indiaotpapp</p>
                <p>https://t.me/india_otp_app (Group)</p>
              </div>
            </div>
        </div>
      </div>
    );
  }
  
  export default Support;