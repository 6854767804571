import { Link } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';
import { useState } from "react";

function Panel() {

    const [showTrans, setTrans] = useState('');
    const [showAddMoney, setAddMoney] = useState(false);
    const [amount, setAmount] = useState(0);
    const [tid, setTID] = useState('');
    //let showTransaction = false;

  function showAlert(){
    if(tid.length == 12){
      setAmount(200);
      localStorage.setItem("amount", 200);
      setTrans('success');
    }
    else{
      setTrans('failed');
    }
    
    setTimeout(()=>{
        setTrans('');
    }, 1500);
  }

  setTimeout(()=>{
    let amt = localStorage.getItem("amount") ?? 0;
    setAmount(amt);
  });

  function showAddMoneyDiv(){
    setAddMoney(!showAddMoney);
  }

  const name = localStorage.getItem("email");
  const email = localStorage.getItem("name");

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 text-center">
          <div className="shadow p-4 rounded mt-5">
            <h1>Panel</h1>
            <p>Current Balance Rs.{ amount }</p>
            <p>{ name } | { email }</p>
            <button className="btn btn-primary" onClick={showAddMoneyDiv}>Add Money</button>
            <Link className='myLink' to="/transactions"><button className="btn btn-primary">Transactions</button></Link>
            {
              showAddMoney==true?
            <div className="row mt-5 p-4 border">
                <div className="col-md-6 text-center">
                    <h3>Scan QR and Pay</h3>
                    <p>After successful payment enter transaction id and submit</p>
                    <img src="qrcode.png" style={{width: '300px'}} alt="qr code"/>
                    <p><small style={{color: 'green'}}>Minimum Transaction Limit is Rs. 200</small></p>
                    <p><small>Transaction related issues contact us from support.</small></p>
                </div>
                <div className="col-md-6 text-start">
                    <input type="text" name="tid" placeholder="Enter Transaction ID" onInput={e => setTID(e.target.value)} className="form-control" required/>
                    <button className="btn btn-primary" onClick={showAlert}>Submit</button>
                    <p><small style={{color: 'green'}}>After transaction wait for 10-15min</small></p>
                    {
                        showTrans == 'success'?
                            <Alert key={'success'} variant={'success'}>
                            Successfully added
                        </Alert>: ''
                    }
                    {
                        showTrans == 'failed'?
                            <Alert key={'danger'} variant={'danger'}>
                            Inter valid transaction id
                        </Alert>: ''
                    }
                </div>
                
            </div>
            :
            ''
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Panel;
